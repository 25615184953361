import { useApolloClient } from "@apollo/react-hooks";
import Dialog from "rc-dialog";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IS_LOGGED_IN from "../../graphql/queries/login.query";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import eventBus from "../../utils/eventBus.utils";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";

import { StyledButton } from "../styles/styled-button";

const UserStatusMessage = ({ setAuthenticated }) => {

  const history = useHistory();

  const client = useApolloClient();

  const [visible, setVisible] = useState(false);

  const [status, setStatus] = useState("");
  const [cookies] = useCookies(["i18next"]);

  const { userId } = client.readQuery({ query: GET_USER_RBAC_DETAILS });

  const subscribeToEvent = (channel) => {
    PusherService.connectToSocket(
      channel,
      pusherEvents.USER.UPDATE,
      (data) => {
        let userStatus = JSON.parse(data);

        if ("status_text" in userStatus && localStorage.getItem("token")) {
          setStatus(userStatus.status_text);
          setVisible(true);
          setAuthenticated(false);

          client.writeQuery({
            query: IS_LOGGED_IN,
            data: { isLoggedIn: false }
          });

          localStorage.removeItem("token");
          localStorage.removeItem("country");

          PusherService.unsubscribeFromAllChannels();
        }
      }
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  };

  const yes = () => {
    setVisible(false);
    history.push("/login");
  };

  useEffect(() => {
    let channel = null;

    if (userId !== '') {
      channel = `${pusherChannels.USER.USER_STATUS}-${userId}`;

      subscribeToEvent(channel);
    } else {
      eventBus.on("login_user", (data) => {
        if ("id" in data) {
          channel = `${pusherChannels.USER.USER_STATUS}-${data.id}`;

          subscribeToEvent(channel);
        }
      });

      eventBus.remove("login_user");
    }
  }, []);

  const attentionText = (lang) => {
    switch (lang) {
      case "en":
        return "Attention!";
      case "ru":
        return "Внимание!";
      case "ua":
        return "Увага!";
    }
  };

  const modalContent = () => {
    const style = {
      textAlign: "justify"
    };

    return (
      <>
        <div className="default-modal__body-content">
          <p style={style}>
            {status}
          </p>
        </div>
        <div className="default-modal__body-footer">
          <StyledButton
            color="main"
            onClick={yes}
            weight="normal"
          >
            OK
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <Dialog
      visible={visible}
      wrapClassName="rc-modal-center"
      closeIcon={false}
      closable={false}
      animation="zoom"
      maskAnimation="fade"
      title={attentionText(cookies.i18next)}
      className="default-modal"
    >
      {modalContent()}
    </Dialog>
  );
};

export default UserStatusMessage;