import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { GET_NEW_REQUISITIONS_COUNT } from "../../graphql/queries/payout-requisitions.query";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";
import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationReferralRequisitions = () => {
  const [count, setCount] = useState("");

  const { refetch } = useQuery(GET_NEW_REQUISITIONS_COUNT, {
    onCompleted: (data) => {
      if (data) {
        const { payoutRequisitions } = data;
        if (payoutRequisitions.length !== 0) {
          const { totalCount } = payoutRequisitions.paginationInfo;
          setCount(totalCount);
        }
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    let channel = pusherChannels.PAYOUT_REQUISITION.PAYOUT_REQUISITION_BADGE;

    if (!refetch) {
      return;
    }

    PusherService.connectToSocket(
      channel,
      pusherEvents.PAYOUT_REQUISITION.UPDATE,
      () => {refetch();}
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, [refetch]);

  if (count <= 0) return null

  return (
    <StyledBadgeNotification>
      {count}
    </StyledBadgeNotification>
  );
};

export default BadgeNotificationReferralRequisitions;