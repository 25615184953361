// Splitting a string by token will produce either, an array with one string (same value), in case token does not exist in a string, or an array with two strings,
// in case token is found in a string .
//
// The first (left) element is string of what was before the token, and the second one (right) is what is string of what was after the token.
// (NOTE: in case string starts with a token, first element is an empty string)
//
// Considering that cookies are stored as follows:
//
// "{name}={value}; {name}={value}; ..."
// in order to retrieve specific cookie value, we just need to get string that is after "; {name}=" and before next ";". Before we do any processing, we prepend the cookies string with "; ",
// so that every cookie name, including the first one, is enclosed with "; " and "=":
//
// "; {name}={value}; {name}={value}; ..."
// Now, we can first split by "; {name}=", and if token is found in a cookie string (i.e. we have two elements), we will end up with second element being a string that begins with our cookie value.
// Then we pull that out from an array (i.e. pop), and repeat the same process, but now with ";" as a token, but this time pulling out the left string (i.e. shift) to get the actual token value.

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  const validPartsLength = 2;

  if (parts.length === validPartsLength) {
    return parts.pop().split(';').shift();
  }
}

