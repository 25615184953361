import { useApolloClient, useQuery } from "@apollo/react-hooks";
import Drawer from "rc-drawer";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../App";
import {
  GET_ADMIN_ACCOUNT_DETAILS,
  GET_CLIENT_ACCOUNT_DETAILS,
  GET_MANAGER_ACCOUNT_DETAILS,
  GET_SEO_ACCOUNT_DETAILS
} from "../../graphql/queries/account.query";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import { responseStatus, userRoles } from "../../utils/consts.util";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";
import CustomLink from "../customLink/CustomLink";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import SidebarItem from "./sidebar-itemet.component";
import SidebarLinks from "./sidebar-links.component";
import SidebarButtonSkeleton from "./skeleton/sidebar-button-skeleton";

import { StyledAccount, StyledShowSidebar, StyledSidebar } from "./styled-sidebar";

const Sidebar = () => {

  let history = useHistory();
  const client = useApolloClient();
  const { t, ready } = useTranslation("sideBar", { useSuspense: false });

  const { setAuthenticated } = useContext(AuthContext);

  const { userId, userRole } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  const DETAIL_QUERIES = {
    GET_ADMIN_ACCOUNT_DETAILS,
    GET_MANAGER_ACCOUNT_DETAILS,
    GET_SEO_ACCOUNT_DETAILS,
    GET_CLIENT_ACCOUNT_DETAILS
  };

  const { data, loading, error, refetch } = useQuery(
    DETAIL_QUERIES[`GET_${userRole.toUpperCase()}_ACCOUNT_DETAILS`] ?? GET_CLIENT_ACCOUNT_DETAILS,
    {
      variables: { id: `/api/${userRole}s/${userId}` },
      fetchPolicy: "network-only"
    }
  );

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let channel = `${pusherChannels.USER.VERIFY_UPDATE}-${userId}`;

    if (!refetch) {
      return;
    }

    PusherService.connectToSocket(
      channel,
      pusherEvents.USER.UPDATE,
      () => {refetch();}
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, [refetch, userId]);

  const logOut = async () => {
    setAuthenticated(false);

    if (userRole === userRoles.ROLE_MANAGER) {
      localStorage.removeItem("refresh_token");
    }

    localStorage.removeItem("token");
    localStorage.removeItem("country");

    PusherService.unsubscribeFromAllChannels();

    history.push("/");
  };

  if (loading) {
    return <SidebarButtonSkeleton text={t("profile")} />;
  }

  if (error) {
    if (error.networkError && error.networkError.statusCode && error.networkError.statusCode === responseStatus.HTTP_UNAUTHORIZED) {
      logOut().finally(() => {
        client.writeData({
          data: { isLoggedIn: false, userId: "", userRole: "anonymous", username: "" }
        });
        closableNotificationWithClick(t("logout"), "error");
      });
    }

    return <SidebarButtonSkeleton text={t("profile")} />;
  }

  if (!data) {
    return <SidebarButtonSkeleton text={t("profile")} />;
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const clearClientData = () => {
    logOut().finally(() => {
      client.writeData({
        data: { isLoggedIn: false, userId: "", userRole: "anonymous", username: "" }
      });
    });
  };

  const user = userRole === userRoles.ROLE_CLIENT ? data.accountDetailsClient : data[userRole];

  if (!ready) {
    return null;
  }

  return (
    <>
      <StyledShowSidebar
        className="sidebar-btn"
        onClick={showDrawer}
      >
        <div className="sidebar-btn__icon">
          <span className="icon-bar" />
        </div>
        <div className="sidebar-btn__title">{t("profile")}</div>
      </StyledShowSidebar>
      <Drawer
        width="256"
        placement="right"
        handler={false}
        open={visible}
        onClose={onClose}
      >
        <StyledSidebar>
          <StyledAccount className="sidebar-account">
            <div className="sidebar-account__info user">
              <div className="user__name">
                {user.firstname} {user.lastname}
              </div>
              <div className="user__email">{user.email}</div>
            </div>
            <div className="sidebar-account__settings">
              <CustomLink
                to="/panel/account"
                className="settings-link"
                onClick={onClose}
              >
                <span className="icon-settings-cog" />
              </CustomLink>
            </div>
          </StyledAccount>
          <SidebarLinks handleChange={onClose} visible={visible} isBank={user?.isBank}/>
          <SidebarItem
            icon="door-open"
            linkTitle={t("exit")}
            handleChange={() => {
              onClose();
              clearClientData();
            }}
            data-testid="sidebar-exist"
          />
        </StyledSidebar>
      </Drawer>
    </>
  );
};

export default React.memo(Sidebar);
