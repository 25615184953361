import styled from "styled-components";
import { StyledSelect } from "../../styles/styled-img-select";

export const StyledLanguageSelect = styled(StyledSelect)`
  width: 45px;
  margin-left: 30px;

  input {
    display: none;
  }
  
  .lang-select {
    padding: 0;
    width: 100%;
    color: ${({theme}) => theme.text};
    z-index: 1;
    line-height: inherit;
    transition: inherit;
    &:hover {
      color: ${({theme}) => theme.defaultColor};
    }
    .lang-select__item {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    .lang-select__text {
      display: none;
    }
    .rc-select-selector {
      min-height: auto;
      padding: 0;
    }
    .rc-select-selection-item {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    .rc-select-arrow {
      width: 15px;
      height: 15px;
      .rc-select-arrow-icon {
        width: 15px;
        height: 15px;
        font-size: 12px;
        top: 0;
        left: 0;
        &:before {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .rc-select-dropdown {
    .rc-select-item-option {
      padding: 0;
      .lang-select__item {
        padding: 10px 15px;
      }
      .lang-select__pref {
        display: none;
      }
    }
  }

  @media screen and (max-width: 992px) {
    margin-left: 15px;
  }
`;