import { useApolloClient } from "@apollo/react-hooks";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import { authenticationConfig } from "../../utils/authenticationConfig";
import { responseStatus } from "../../utils/consts.util";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { requisitionStatusConst } from "../../utils/requsition.status";
import { PusherService } from "../../utils/socketPusherService";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";

import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationRequisition = () => {
  let permissions = {};
  const client = useApolloClient();

  const { userRole, managerCity } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  const [count, setCount] = useState("");

  switch (userRole) {
    case "manager":
      permissions = { exchangePoint_list: managerCity.length !== 0 ? managerCity : "" };
      break;
  }

  const getSidebarRequisitionsCount = () => {
    const statusList = [
      requisitionStatusConst.NEW,
      requisitionStatusConst.CARD_VERIFICATION,
      requisitionStatusConst.INVOICE,
      requisitionStatusConst.PROCESSED
    ].toString();

    const exchangePointList = managerCity.length ? managerCity.toString() : null;

    let dataString = "?statusList=" + statusList;

    if (exchangePointList) {
      dataString += "&exchangePointList=" + exchangePointList;
    }

    axios.get("/api/panel/requisitions/sidebar-count" + dataString, authenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setCount(response.data);
      }
    }).catch(error => {
      closableNotificationWithClick(error.response.data.detail, "error");
    });
  };

  useEffect(() => {
    let channel = pusherChannels.REQUISITION.REQUISITION_BADGE;

    PusherService.connectToSocket(
      channel,
      pusherEvents.REQUISITION.CREATE,
      getSidebarRequisitionsCount
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.REQUISITION.UPDATE,
      getSidebarRequisitionsCount
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, []);

  useEffect(() => {
    getSidebarRequisitionsCount();
  }, []);

  if (count <= 0) {
    return null;
  }

  return (
    <StyledBadgeNotification>
      {count}
    </StyledBadgeNotification>
  );
};
export default BadgeNotificationRequisition;
