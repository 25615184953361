import React from "react";
// import OldRequisitionPage from "./pages/old-requisition/old-requisition.component";
import guestRoutes from "./guestRoutes";
import { supportedLanguages } from "./utils/consts.util";

const Account = React.lazy(() => import("./pages/account/account.component"));
const PaymentSettingsPage = React.lazy(() => import("./pages/payment-settings/payment-settings.component"));
const RequisitionDetailsPage = React.lazy(() => import("./pages/requisition-details/requisition-details.component"));
const CurrencyPage = React.lazy(() => import("./pages/currency/currency.component"));
const AdminPage = React.lazy(() => import("./pages/admin/admin.component"));
const ManagerPage = React.lazy(() => import("./pages/manager/manager.component"));
const SeoPage = React.lazy(() => import("./pages/seo/seo.component"));
const RequisitionPage = React.lazy(() => import("./pages/requisition/requisition.component"));
const NewsCreatePost = React.lazy(() => import("./components/news/news-create-post.component"));
const NewsEditForm = React.lazy(() => import("./components/news/news-edit-form.component"));
const ReviewsAdmin = React.lazy(() => import("./pages/review/review-admin.component"));
const ReviewEditPanel = React.lazy(() => import("./components/review/review-admin-edit.component"));
const NewsAdmin = React.lazy(() => import("./pages/news/news-admin.component"));
const LoyaltyProgramPage = React.lazy(() => import("./pages/loyalty-program/loyalty-program.component"));
const BankDetailsPage = React.lazy(() => import("./pages/bank-details/bank-details.component"));
const ReportsPage = React.lazy(() => import("./pages/reports/reports.component"));
const ManagersDetailsPage = React.lazy(() => import("./pages/manager/manager-details.component"));
const SeoDetailsPage = React.lazy(() => import("./pages/seo/seo-details.component"));
const ReferralReportDetails = React.lazy(
  () => import("./components/reports/referral-reports/referral-report-details.component"));
const FeedbackPage = React.lazy(() => import("./pages/feedback/feedbacks-page.component"));
const FeedbackDetailsPage = React.lazy(() => import("./pages/feedback/feedback-details-page.component"));
const CityContactsPage = React.lazy(() => import("./pages/city-contacs/city-contacts.component"));
const CityDetailsPage = React.lazy(() => import("./pages/city-descriptions/city-descriptions.component"));
const CityDetailsEditPage = React.lazy(() => import("./pages/city-descriptions/city-description-edit.component"));
const CityDetailsCreatePage = React.lazy(() => import("./pages/city-descriptions/city-description-create.component"));
const TrafficDetails = React.lazy(() => import("./components/reports/traffic-reports/traffic-details.component"));
const DocumentVerificationPage = React.lazy(() => import("./pages/document/document-verification.component"));
const DocumentPage = React.lazy(() => import("./pages/document/document.component"));
const CardVerificationPage = React.lazy(() => import("./pages/card-verification/card-verification.component"));
const CardVerificationDetailsPage = React.lazy(
  () => import("./pages/card-verification-details/card-verification-details.component"));
const CardVerificationUpdatePage = React.lazy(() => import("./pages/card-verification/card-verification-update.page"));
const ReferralClientsReportsContainer = React.lazy(
  () => import("./components/reports/referral-reports/referral-statistics/referral-list/client-referral-report.container"));
// const MailingPage = React.lazy(() => import("./pages/mailing/mailing.component"));
// const MailingDetailsPage = React.lazy(() => import("./pages/mailing/mailing-detail.component"));
const AdminDetailsPage = React.lazy(() => import("./pages/admin/admin-details.component"));
const ClientPage = React.lazy(() => import("./pages/client/client.component"));
const ClientDetailsPage = React.lazy(() => import("./pages/client-details/client-details.component"));
const LogsPage = React.lazy(() => import("./pages/logs/logs.component"));
const ManagersReportsDetailsContainer = React.lazy(
  () => import("./components/reports/manager-reports/managers-reports-details.container"));
const TrafficReportsClientDetailsContainer = React.lazy(
  () => import("./components/reports/traffic-reports/traffic-reports-client-details-container.component"));
const TrafficReportsRequisitionDetailsContainer = React.lazy(
  () => import("./components/reports/traffic-reports/traffic-reports-requisition-details.container"));
const AccountDeletePage = React.lazy(() => import("./pages/account/account-delete.component"));
const TemplatesPage = React.lazy(() => import("./pages/templates-of-answers/templates.component"));
const SeoManualPage = React.lazy(() => import("./pages/manual/seo-manual.component"));
const ManagerManualPage = React.lazy(() => import("./pages/manual/manager-manual.component"));
const AdminManualPage = React.lazy(() => import("./pages/manual/admin-manual.component"));
const ClientIBANVerificationPage = React.lazy(() => import("./pages/client-iban/client-iban.component"));
const ClientIBANDetailsPage = React.lazy(() => import("./pages/client-iban/client-iban-details.component"));
const EmailVerificationPage = React.lazy(() => import("./pages/registration/emailVerification.component"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`
});

const routes = [
  {
    title: "CardVerificationDetailsPage",
    path: `/:locale(${locale})?/panel/card-verification/details/:id`,
    component: CardVerificationDetailsPage
  },
  {
    title: "CardVerificationUpdatePage",
    path: `/:locale(${locale})?/panel/card-verification/edit/:id`,
    component: CardVerificationUpdatePage
  },
  {
    title: "CardVerificationPage",
    path: `/:locale(${locale})?/panel/card-verification`,
    component: CardVerificationPage
  },
  {
    title: "ClientIBANVerificationPage",
    path: `/:locale(${locale})?/panel/iban-verification`,
    component: ClientIBANVerificationPage
  },
  {
    title: "ClientIBANDetailsPage",
    path: `/:locale(${locale})?/panel/client-iban/details/:id`,
    component: ClientIBANDetailsPage
  },
  {
    title: "PaymentSettingsPage",
    path: `/:locale(${locale})?/panel/payment-settings`,
    component: PaymentSettingsPage
  },
  {
    title: "Account",
    path: `/:locale(${locale})?/panel/account`,
    component: Account
  },
  {
    title: "Loyalty program",
    path: `/:locale(${locale})?/panel/loyalty-program`,
    component: LoyaltyProgramPage
  },
  {
    title: "DocumentVerificationPage",
    path: `/:locale(${locale})?/panel/document/verification`,
    component: DocumentVerificationPage
  },
  {
    title: "DocumentPage",
    path: `/:locale(${locale})?/panel/documents`,
    component: DocumentPage
  },
  {
    title: "RequisitionPage",
    path: `/:locale(${locale})?/panel/requisitions`,
    component: RequisitionPage
  },
  // {
  //   title: "OldRequisitionPage",
  //   path: `/:locale(${locale})?/panel/old-requisitions`,
  //   component: OldRequisitionPage
  // },
  {
    title: "RequisitionDetailsPage",
    path: `/:locale(${locale})?/panel/requisition-details/:id`,
    component: RequisitionDetailsPage
  },
  {
    title: "ClientDetailsPage",
    path: `/:locale(${locale})?/panel/clients/:id`,
    component: ClientDetailsPage
  },
  {
    title: "ClientPage",
    path: `/:locale(${locale})?/panel/clients`,
    component: ClientPage
  },
  {
    title: "CurrencyPage",
    path: `/:locale(${locale})?/panel/currencies`,
    component: CurrencyPage
  },
  {
    title: "AdminPage",
    path: `/:locale(${locale})?/panel/admins`,
    component: AdminPage
  },
  {
    title: "ManagerDetailsPage",
    path: `/:locale(${locale})?/panel/manager/:id`,
    component: ManagersDetailsPage
  },
  {
    title: "SeoDetailsPage",
    path: `/:locale(${locale})?/panel/seo/:id`,
    component: SeoDetailsPage
  },
  {
    title: "AdminDetailsPage",
    path: `/:locale(${locale})?/panel/admin/:id`,
    component: AdminDetailsPage
  },
  {
    title: "ManagerPage",
    path: `/:locale(${locale})?/panel/managers`,
    component: ManagerPage
  },
  {
    title: "SeoPage",
    path: `/:locale(${locale})?/panel/seos`,
    component: SeoPage
  },
  {
    title: "NewsEditForm",
    path: `/:locale(${locale})?/panel/news/edit/:id`,
    component: NewsEditForm
  },
  {
    title: "ReferralReportDetails",
    path: `/:locale(${locale})?/panel/reports/details/:id`,
    component: ReferralReportDetails
  },
  {
    title: "LoyaltyReferrals",
    path: `/:locale(${locale})?/panel/reports/details/:id?currentTab=:referralLevel?`,
    component: ReferralClientsReportsContainer
  },
  {
    title: "TrafficDetails",
    path: `/:locale(${locale})?/panel/reports/traffic-details/:id`,
    component: TrafficDetails
  },
  {
    title: "TrafficReportsClientDetailsContainer",
    path: `/:locale(${locale})?/panel/reports/traffic-details-clients/:id`,
    component: TrafficReportsClientDetailsContainer
  },
  {
    title: "TrafficReportsRequisitionDetailsContainer",
    path: `/:locale(${locale})?/panel/reports/traffic-details-requisitions/:id`,
    component: TrafficReportsRequisitionDetailsContainer
  },
  {
    title: "NewsCreatePost",
    path: `/:locale(${locale})?/panel/news/create`,
    component: NewsCreatePost
  },
  {
    title: "NewsAdmin",
    path: `/:locale(${locale})?/panel/news`,
    exact: true,
    component: NewsAdmin
  },
  {
    title: "ReviewsAdmin",
    path: `/:locale(${locale})?/panel/reviews`,
    component: ReviewsAdmin
  },
  {
    title: "LogsPage",
    path: `/:locale(${locale})?/panel/logs`,
    component: LogsPage
  },
  {
    title: "ReviewEdit",
    path: `/:locale(${locale})?/panel/review/edit/:id`,
    component: ReviewEditPanel
  },
  {
    title: "BankDetailsPage",
    path: `/:locale(${locale})?/panel/bank-details`,
    component: BankDetailsPage
  },
  {
    title: "Reports",
    path: `/:locale(${locale})?/panel/reports`,
    component: ReportsPage
  },
  {
    title: "FeedbacksPage",
    path: `/:locale(${locale})?/panel/feedbacks`,
    component: FeedbackPage
  },
  {
    title: "FeedbackDetailsPage",
    path: `/:locale(${locale})?/panel/feedback/details/:id`,
    component: FeedbackDetailsPage
  },
  {
    title: "ContactsPage",
    path: `/:locale(${locale})?/panel/city-contacts`,
    component: CityContactsPage
  },
  /*MailingPage and MailingDetailsPage commented according  https://trello.com/c/aJ8GVTdb*/
  // {
  //   title: "MailingPage",
  //   path: `/:locale(${locale})?/panel/mailing`,
  //   component: MailingPage
  // },
  // {
  //   title: "MailingDetailsPage",
  //   path: `/:locale(${locale})?/panel/mailings/details/:id`,
  //   component: MailingDetailsPage
  // },
  {
    title: "CityDetailsEdit",
    path: `/:locale(${locale})?/panel/city-details/edit/:id`,
    component: CityDetailsEditPage
  },
  {
    title: "CityDetailsCreate",
    path: `/:locale(${locale})?/panel/city-details/create`,
    component: CityDetailsCreatePage
  },
  {
    title: "CityDetails",
    path: `/:locale(${locale})?/panel/city-details`,
    component: CityDetailsPage
  },
  {
    title: "ManagerReportsDetailsPage",
    path: `/:locale(${locale})?/panel/reports-manager-details/:id`,
    component: ManagersReportsDetailsContainer
  },
  {
    title: "AccountDeletePage",
    path: `/:locale(${locale})?/account-delete`,
    component: AccountDeletePage
  },
  {
    title: "TemplatesPage",
    path: `/:locale(${locale})?/panel/templates`,
    component: TemplatesPage
  },
  {
    title: "ManualSeo",
    path: `/:locale(${locale})?/panel/manual/seo`,
    component: SeoManualPage
  },
  {
    title: "ManualManager",
    path: `/:locale(${locale})?/panel/manual/manager`,
    component: ManagerManualPage
  },
  {
    title: "AdminManager",
    path: `/:locale(${locale})?/panel/manual/admin`,
    component: AdminManualPage
  }
];

const routesConcat = routes.concat(guestRoutes);

export default routesConcat;
