import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSynchronizedTime } from "../../utils/serverTime";
import CookieMessage from "../cookie-message/cookie-message.component";

import FooterDocumentLinks from "./footer-document-links";
import FooterInfoLinks from "./footer-info-links";
import FooterMerchants from "./footer-merchants";
import FooterSocialLinks from "./footer-social-links";
import FooterDeveloped from "./footer.developed";

import { StyledContainer } from "../styles/styled-container";
import { StyledFooter, StyledFooterContent } from "./styled-footer";

const Footer = ({ theme }) => {
  const initialCookies = {
    strictlyNecessary: true,
    functional: false,
    performance: false,
    targeting: false,
    agree: false
  };
  const [cookie, setCookie] = useState(initialCookies);

  const currentYear = (getSynchronizedTime().getFullYear());

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <StyledFooter>
      {!Cookies.get("useCookiesConsent") && <CookieMessage cookie={cookie} setCookie={setCookie} />}
      <StyledContainer wrapper="content">
        <StyledFooterContent>
          <FooterInfoLinks />
          <FooterDocumentLinks />
          <FooterSocialLinks />
        </StyledFooterContent>
        <FooterMerchants theme={theme} />
        <div className="footer-author">
          {ready && `© Exchange-247, ${process.env.REACT_APP_YEAR_OF_FOUNDATION}-${currentYear}.  ${t("rights")}`}
        </div>
        <FooterDeveloped theme={theme} />
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;