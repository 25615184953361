import React from "react";
import { StyledDeveloperBy } from "./styled-footer";

const FooterDeveloped = ({ theme }) => {
  return (
    <StyledDeveloperBy>
      <a href="https://exsoft.io/" className="link-wrapper" target="_blank">
        <span className={`exsoft-logo-${theme}`} />
        <p>
          Developed by <span className="default-link">exsoft.io</span>
        </p>
      </a>
    </StyledDeveloperBy>
  );
};

export default FooterDeveloped;