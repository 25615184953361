import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../customLink/CustomLink";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterDocumentLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });
  
  return (
    <StyledFooterBlock className="footer-document-links">
      <StyledFooterContentItem className="footer-item">
        <div className="footer-item__title">
          {ready && t("linkDocsTitle")}
        </div>
        <ul className="footer-item__list">
          <li>
            <CustomLink to="/useterms">
              {ready && t("linkDocsAgree")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/privacy">
              {ready && t("linkDocsConf")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/kyc-and-aml">
              {ready && t("linkDocsAML")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/exchange-regulations">
              {ready && t("linkDocsReglam")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/cookies-policy">
              {ready && t("linkDocsCookie")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/card-verification-manual">
              {ready && t("linkDocsVerify")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/iban-verification-manual">
              {ready && t("IBAN_verification")}
            </CustomLink>
          </li>
        </ul>
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterDocumentLinks;