import React from "react";
import { useTranslation } from "react-i18next";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterSocialLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });
  
  return (
    <StyledFooterBlock className="footer-social-links">
      <StyledFooterContentItem className="footer-item footer-social-links__work-time">
        <div className="footer-item__title">
          {ready && t("linkTimeTitle")}
        </div>
        <ul className="footer-item__list">
          <li>
            {ready && t("linkTimeWork")}
          </li>
          <li>
            {ready && t("linkTimeTP")}
          </li>
        </ul>
      </StyledFooterContentItem>
      <StyledFooterContentItem className="footer-item footer-social-links__email">
        <div className="footer-item__title">
          {ready && t("linkEmailTitle")}
        </div>
        {
          ready &&
          <ul className="footer-item__list">
           <li>
              <a href="mailto:support@exchange-247.com">
                support@exchange-247.com
              </a>
            </li>
            <li>
              <p>{t("mailUs")}</p>
            </li>
          </ul>
        }
      </StyledFooterContentItem>
      <StyledFooterContentItem className="footer-item footer-social-links__social-list">
        <div className="footer-item__title">
          {ready && t("linkSocTitle")}
        </div>
        {
        ready &&
          <ul className="footer-item__list social-wrapper">
            <li>
              <a
                className="social-icon__link"
                href="https://t.me/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="telegram"
              >
                <span className="icon-telegram1" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://www.facebook.com/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="facebook"
              >
                <span className="icon-facebook1" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://twitter.com/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="twitter"
              >
                <span className="icon-twitter" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://instagram.com/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="instagram"
              >
                <span className="icon-instagram" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://www.youtube.com/@exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="youtube"
              >
                <span className="icon-youtube" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://www.linkedin.com/company/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="linkedin"
              >
                <span className="icon-linkedin" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://vk.com/exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="vk"
              >
                <span className="icon-vk" />
              </a>
            </li>
            <li>
              <a
                className="social-icon__link"
                href="https://tiktok.com/@exchng247/"
                target="_blank"
                rel="noreferrer"
                aria-label="tiktok"
              >
                <span className="icon-tiktok" />
              </a>
            </li>
          </ul>
        }
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterSocialLinks;