import Pusher from "pusher-js";
import { getCookie } from "./cookies";

//  Subscribe to pusher in react component example:
//  1) Import Pusher from AppContext: const {PusherService} = useContext(AppContext);
//  2) For subscribe to channel and event use function PusherService.connectToSocket(Pusher, channelName, eventName, callback)

const PRIVATE_CHANNEL_OBJECT_NAME = "PrivateChannel";

// DebugMode
Pusher.logToConsole = false;

let pusherOptions = {
  cluster: "eu",
  enabledTransports: ['ws'],
  useTLS: true
};

let pusherAuthHeaders = {
  auth: {
    authEndpoint: process.env.REACT_APP_SOCKET_AUTH_ENDPOINT,
    headers: {
      "Authorization": "Bearer "
    }
  }
};

export const PusherService = {
  pusher: null,

  /**
   * @param channelName
   * @returns {*}
   */
  getChannelObject (channelName) {
    return this.pusher?.channels?.channels[channelName];
  },

  initNewAuthConnection () {
    let token = getCookie("token");

    if (token) {
      pusherAuthHeaders.auth.headers.Authorization = pusherAuthHeaders.auth.headers.Authorization + token;
      pusherOptions = { ...pusherOptions, ...pusherAuthHeaders };
    }

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_PUBLIC_KEY, pusherOptions);
  },

  initNewConnection () {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_PUBLIC_KEY, pusherOptions);
  },

  /**
   * @param channelName
   * @param eventName
   * @param callback
   */
  connectToSocket (channelName, eventName, callback) {

    if (!this.pusher) {
      this.initNewConnection();
    }

    if (!Object.keys(this.pusher.channels.channels).includes(channelName)) {
      this.pusher.subscribe(channelName);
    }

    this.pusher.channels.channels[channelName].bind(eventName, callback);
  },

  unsubscribeFromPrivateChannels () {
    if (!this.pusher.channels.channels) {
      return;
    }

    Object.values(this.pusher.channels.channels).forEach(channel => {
      if (typeof channel === "object" && channel.constructor.name === PRIVATE_CHANNEL_OBJECT_NAME) {
        this.pusher.unsubscribe(channel.name);
      }
    });
  },

  unsubscribeFromAllChannels () {
    if (!this.pusher.channels.channels) {
      return;
    }

    Object.values(this.pusher.channels.channels).forEach(channel => {
      if (typeof channel === "object") {
        this.pusher.unsubscribe(channel.name);
      }
    });
  },

  /**
   * Only for private channels, such as: PusherService.triggerEvent("private-crash." + user.userId, "init", {"test": "asd"});
   * @param channelName
   * @param eventName
   * @param eventData
   */
  triggerEvent (channelName, eventName, eventData) {
    let channel = this.getChannelObject(channelName);

    if (channel === undefined) {
      this.pusher.subscribe(channelName);
      channel = this.getChannelObject(channelName);
    }

    channel.bind("pusher:subscription_succeeded", () => {
      channel.trigger("client-" + eventName, eventData);
    });
  },

  /**
   * @param channelName
   */
  unsubscribeFromChannel (channelName) {
    if (!this.pusher.channels.channels) {
      return;
    }

    this.pusher.unsubscribe(channelName);
  },

  /**
   * @param channelNames
   */
  unsubscribeFromChannelArray (channelNames) {
    if (!this.pusher.channels.channels || !Array.isArray(channelNames)) {
      return;
    }

    channelNames.forEach(channelName => {
      this.pusher.unsubscribe(channelName);
    });
  },

  /**
   * @param pusherObject
   * @param stateList
   * @param setStateList
   * @param searchableField
   */
  updateOrPushObjectInList (pusherObject, stateList, setStateList, searchableField = "id") {
    if (!stateList || !setStateList) {
      return;
    }

    let newArray = [];
    let objectIndexFromList = stateList.findIndex(state => state[searchableField] === pusherObject[searchableField]);

    if (objectIndexFromList < 0) {
      stateList.unshift(pusherObject);

      setStateList([...stateList, ...newArray]);

      return;
    }

    stateList[objectIndexFromList] = pusherObject;

    setStateList([...stateList, ...newArray]);
  },

  /**
   * @param pusherObject
   * @param state
   * @param setState
   */
  updateSingleObject (pusherObject, state, setState) {
    if (!state || !setState) {
      return;
    }

    setState(pusherObject);
  }
};