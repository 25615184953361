import styled from 'styled-components';

const tableSize = {
  size: ''
}

const changeContainerSize = (size) => {
  switch (size) {
    case 'xl':
      return `
        max-width: 1920px
      `
    default:
      return `
        max-width: 1140px
      `
  }
}

export const StyledContainer = styled('div', tableSize)`
  --header: 95px;
  --footer: 458px;
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  padding: ${({ wrapper }) => wrapper !== 'content' ? '100px 15px 0' : '0 15px'};
  ${({ wrapper }) => wrapper !== 'content' && `min-height: calc(100vh - var(--header) - var(--footer))`};
  ${({ wrapper }) => wrapper !== 'content' && `padding-bottom: 30px`};
  @media screen and (max-width: 992px) {
    padding: 0 15px;
    ${({ wrapper }) => wrapper !== 'content' && `height: auto`};
    ${({ wrapper }) => wrapper !== 'content' && `padding-bottom: 50px`};
  }
`;
