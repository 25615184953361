import styled from "styled-components";

export const StyledLogo = styled.div`
  display: inline-flex;
  
  ${({ fixed }) => fixed && `
    .logo-icon {
      width: 35px;
      height: 46px !important; 
      
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  `};
`;

export const StyledLogoIconWrapper = styled.div`
  width: 50px;
  height: 46px;
  z-index: 1;
`;


export const StyledLogoIcon = styled.div`
  width: 45px;
  height: 60px;
  z-index: 1;
  transition: all .3s ease;
  img {
    width: 100%;
  }
`;