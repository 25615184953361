import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../customLink/CustomLink";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterInfoLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <StyledFooterBlock className="footer-info-links">
      <StyledFooterContentItem className="footer-item">
        <div className="footer-item__title">
          {ready && t("linkInfoTitle")}
        </div>
        <ul className="footer-item__list">
          <li>
            <CustomLink to="/about-us">
              {ready && t("linkInfoAbout")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/rates">
              {ready && t("linkInfoCourse")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/reviews">
              {ready && t("linkInfoReviews")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/partners">
              {ready && t("linkInfoPartners")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/client-manual">
              {ready && t("linkInfoManual")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/faq">
              {ready && t("linkInfoFaq")}
            </CustomLink>
          </li>
        </ul>
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterInfoLinks;