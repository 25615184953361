import styled from "styled-components";

const colorButton = {
  color: String
};

const styledSize = (size) => {
  switch (size) {
    case "small":
      return `
        padding: 4px 8px;
        font-size: 12px;
      `;
    default:
      return ``;
  }
};

const styleButton = (theme, color, border) => {
  switch (color) {
    case "main":
      return `
        color: ${theme.blackText};
        background: ${theme.defaultColor};
        border: 1px solid ${theme.defaultColor};
        border: none;
        &:hover {
          background-color: ${border ? theme.primaryColors.first : theme.primaryColors.first};
        }
    `;
    case "success":
      return `
        color: ${border ? theme.successColors.first : theme.blackText};
        background-color: ${border ? "transparent" : theme.successColors.first};
        border: 1px solid ${theme.successColors.first};
        &:hover {
          background-color: ${border ? theme.successColors.third : theme.successColors.second};
        }
     `;
    case "danger":
      return `
        color: ${border ? theme.dangerColors.first : theme.blackText};
        background-color: ${border ? "transparent" : theme.dangerColors.first};
        border: 1px solid ${theme.dangerColors.first};
        &:hover {
          background-color: ${border ? theme.dangerColors.third : theme.dangerColors.second};
        }
      `;
    case "warning":
      return `
        color: ${border ? theme.warningColors.first : theme.blackText};
        background-color: ${border ? "transparent" : theme.warningColors.first};
        border: 1px solid ${theme.warningColors.first};
         &:hover {
          background-color: ${border ? theme.warningColors.third : theme.warningColors.second};
        }
     `;
    case "info":
      return `
        color: ${border ? theme.blackText : theme.whiteText};
        background-color: ${border ? "transparent" : theme.secondaryColors.first};
        border: 1px solid ${theme.secondaryColors.first};
         &:hover {
          background-color: ${border ? "transparent" : theme.neutralColors.second};
          border: 1px solid ${theme.secondaryColors.second};
          transform: scale(1.05);
        }
     `;
    default:
      return `
        color: ${theme.neutralColors.second};
        background: transparent;
        border: 1px solid ${theme.neutralColors.second};
        &:hover {
          color: ${theme.neutralColors.first};
          border: 1px solid ${theme.neutralColors.first};
        }
    `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  width: ${({ figure }) => figure !== "circle" ? "auto" : "46px"};
  height: ${({ figure }) => figure !== "circle" ? "auto" : "46px"};
  padding: ${({ figure }) => figure !== "circle" ? "12px 16px" : "0"};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  //font-weight: ${({ weight }) => weight === "normal" ? "400" : "700"};
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "6px"};
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};

  ${({ theme, color, border }) => styleButton(theme, color, border)}
  ${({ size }) => styledSize(size)}

  &:active {
    transform: scale(0.95);
  }
`;
