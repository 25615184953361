import React from "react";
import { useTranslation } from "react-i18next";

import CustomLink from "../customLink/CustomLink";

import Exchange247Logo from "../../assets/images/logo.png";

import { StyledLogo, StyledLogoIcon, StyledLogoIconWrapper } from "./styled-logo";

const Logo = ({ fixed }) => {

  const { t, ready } = useTranslation("navigations", { useSuspense: false });

  return (
    <CustomLink to="/" className="logo">
      <StyledLogo fixed={fixed}>
        <StyledLogoIconWrapper>
          <StyledLogoIcon className="logo-icon">
            <img alt="exhange-247-logo" src={Exchange247Logo} width="45" height="auto" />
          </StyledLogoIcon>
        </StyledLogoIconWrapper>
      </StyledLogo>
    </CustomLink>
  );
};

export default Logo;