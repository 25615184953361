const authenticationConfig = () => {
  if (localStorage.getItem('token') !== '') {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };
  }
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

const basicAuthenticationConfig = (userCredentials) => {
  return {
    headers: {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + Buffer.from(userCredentials.username + ":" + userCredentials.password).toString("base64")
      }
    },
    body: userCredentials
  };
};

const basicTwoFaAuthenticationConfig = (userCredentials) => {
  return {
    headers: {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + Buffer.from(userCredentials.username + ":" + userCredentials.password).toString("base64")
      }
    },
    body: userCredentials
  };
};

const googleAuthenticationConfig = (token) => {
  return {
    headers: {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "GoogleAuth " + token
      }
    }
  };
};

const googleTwoFaAuthenticationConfig = (token, userCredentials) => {
  return {
    headers: {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "GoogleAuth " + token
      }
    },
    body: userCredentials
  };
};

export {
  authenticationConfig,
  basicAuthenticationConfig,
  basicTwoFaAuthenticationConfig,
  googleAuthenticationConfig,
  googleTwoFaAuthenticationConfig
};
