import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";

import client from "./client";
import App from "./App";

document.getElementById("loading") && document.getElementById("loading").remove();
// Apollo Provider attached the client to our React app

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,

  document.getElementById("root")
);
