const requisitionStatusConst = {
  NEW: "NEW",
  // PAYMENT: "PAYMENT",
  INVOICE: "INVOICE",
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  FINISHED: "FINISHED",
  CANCELED: "CANCELED",
  DISABLED: "DISABLED",
  // NOT_PAID: "NOT_PAID",
  ERROR: "ERROR",
  CARD_VERIFICATION: "CARD_VERIFICATION",
  PARTIALLY_PROCESSED: "PARTIALLY_PROCESSED",
};

const requisitionStatusArray = {
  NOT_PROCESSING: "NOT_PROCESSING",
  PROCESSING: "PROCESSING",
  REFUND: "REFUND",
  PAID: "PAID"
};

const arrayValue = (status) => {
  switch (status) {
    case requisitionStatusArray.NOT_PROCESSING: {
      return {
        status: [
          requisitionStatusConst.NEW,
          requisitionStatusConst.CARD_VERIFICATION,
          requisitionStatusConst.INVOICE,
          requisitionStatusConst.PROCESSED
        ]
      } ;
    }
    case requisitionStatusArray.PROCESSING: {
      return {status: [requisitionStatusConst.PENDING, requisitionStatusConst.INVOICE]}
    }
    case requisitionStatusArray.REFUND: {
      return {status: [requisitionStatusConst.DISABLED, requisitionStatusConst.CANCELED], isPaid: true}
    }
    case requisitionStatusArray.PAID: {
      return {status: requisitionStatusConst.ERROR, isPaid: true}
    }
    default:
      return  {status: status};
  }
};

const requisitionStatus = (status) => {
  switch (status) {
    case requisitionStatusConst.PROCESSED: {
      return "requisitionStatuses.PROCESSED";
    }
    case requisitionStatusConst.INVOICE: {
      return "requisitionFilterStatuses.PENDING";
    }
    case requisitionStatusConst.PENDING: {
      return "requisitionFilterStatuses.PENDING";
    }
    case requisitionStatusConst.FINISHED: {
      return "requisitionStatuses.FINISHED";
    }
    case requisitionStatusConst.CARD_VERIFICATION: {
      return "requisitionStatuses.CARD_VERIFICATION";
    }
    case requisitionStatusConst.CANCELED: {
      return "requisitionStatuses.CANCELED";
    }
    case requisitionStatusConst.DISABLED: {
      return "requisitionStatuses.DISABLED";
    }
    case requisitionStatusConst.ERROR: {
      return "requisitionStatuses.ERROR";
    }
    case requisitionStatusArray.PROCESSING: {
      return "requisitionStatuses.PROCESSING";
    }
    case requisitionStatusArray.NOT_PROCESSING: {
      return "requisitionStatuses.NOT_PROCESSING";
    }
    case requisitionStatusConst.NEW: {
      return "requisitionStatuses.NEW";
    }
    case requisitionStatusConst.PARTIALLY_PROCESSED: {
      return "requisitionStatuses.PARTIALLY_PROCESSED";
    }
    default: {
      return "requisitionStatuses.NOT_PAID";
    }
  }
};

export { requisitionStatus, requisitionStatusConst, arrayValue, requisitionStatusArray };
