import styled from "styled-components";

export const StyledScrollButton = styled.button`
  position: fixed;
  bottom: 180px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  color: #fff;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  display: ${({ isVisible }) => isVisible ? "block" : "none"};
  z-index: 11;

  @media screen and (max-width: 768px) {
    right: 6px;
  }

  img {
    z-index: 10000;
    position: relative;
    top: 3px;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${({theme}) => theme.neutralColors.third};
    opacity: 0.75;
    filter: blur(15px);
    border-radius: 50%;
    left: 0;
    top: 0;
  }

  .progress {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -25px;
    left: -25px;
    border-radius: 50%;
    transform: rotate(-90deg);

    .background {
      fill: none;
      stroke: rgba(251, 211, 4, 0.3);
      stroke-width: 3;
    }

    .chart {
      fill: none;
      stroke: rgba(251, 211, 4, 0.8);
      stroke-width: 3;
    }
  }

`;