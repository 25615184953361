const pusherChannels = {
  REQUISITION: {
    REQUISITION_BADGE: "requisition-badge",
    REQUISITION_LIST: "requisitions",
    REQUISITION_ITEM: "requisition"
  },
  INVOICE: {
    INVOICE_ITEM: "invoice",
    INVOICE_CALLBACK_ITEM: "invoice-callback"
  },
  RATES: {
    RATES_LIST: "rates"
  },
  USER: {
    USER_STATUS: "client-status",
    VERIFY_UPDATE: "verify-update"
  },
  CITY: {
    CITY_LIST: "cities",
    CITY_ITEM: "city"
  },
  CREDIT_CARD: {
    CREDIT_CARD_BADGE: "credit-card-badge",
    CREDIT_CARD_LIST: "credit-cards",
    CREDIT_CARD_ITEM: "credit-card"
  },
  FEEDBACK_DETAIL: {
    FEEDBACK_DETAIL_ITEM: "feedback-detail"
  },
  FEEDBACK: {
    FEEDBACK_BADGE: "feedback-badge",
    FEEDBACK_LIST: "feedbacks"
  },
  MAILING: {
    MAILING_ITEM: "mailing",
    MAILING_LIST: "mailings"
  },
  PAIR: {
    PAIR_ITEM: "pair",
    PAIR_LIST: "pairs",
    PAIR_RATES: "pair-rates"
  },
  PAYOUT_REQUISITION: {
    PAYOUT_REQUISITION_BADGE: "payout-requisitions-badge",
    PAYOUT_REQUISITION_ITEM: "payout-requisition",
    PAYOUT_REQUISITION_LIST: "payout-requisitions"
  },
  NEWS: {
    NEWS_ITEM: "news-item",
    NEWS_LIST: "news-list"
  },
  REVIEWS: {
    REVIEWS_BADGE: "review-badge",
    REVIEWS_ITEM: "review",
    REVIEWS_LIST: "reviews"
  },
  TEMPLATES_OF_ANSWERS: {
    TEMPLATES_OF_ANSWERS_LIST: "templates"
  },
  PAIR_UNIT: {
    PAIR_UNIT_ITEM: "pair-unit"
  },
  CLIENT_IBAN: {
    CLIENT_IBAN_BADGE: "client-iban-badge",
    CLIENT_IBAN_ITEM: "client-iban",
    CLIENT_IBAN_LIST: "client-ibans"
  }
};

const pusherEvents = {
  REQUISITION: {
    UPDATE: "update",
    CREATE: "create"
  },
  INVOICE: {
    UPDATE: "update",
    CREATE: "create"
  },
  RATES: {
    UPDATE: "update"
  },
  CITY: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  CREDIT_CARD: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  FEEDBACK_DETAIL: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  FEEDBACK: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  MAILING: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  PAIR: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  PAIR_UNIT: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  PAYOUT_REQUISITION: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  NEWS: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  REVIEWS: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  TEMPLATES_OF_ANSWERS: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  CLIENT_IBAN: {
    UPDATE: "update",
    CREATE: "create",
    DELETE: "delete"
  },
  USER: {
    UPDATE: "update"
  }
};

export {
  pusherChannels,
  pusherEvents
};
