import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { StyledScrollButton } from "./styled-back-to-top";
import backToTopArrow from "../../assets/images/back-to-top-arrow.svg"

const BackToTopButton= () => {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const actionWithScroll = () => {
      toggleVisibility();
      updateProgress();
    };
    document.addEventListener("scroll", actionWithScroll);
    return () => {
      document.removeEventListener("scroll", actionWithScroll);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const updateProgress = () => {
    const scrollPos = document.documentElement.scrollTop;
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const progress = (scrollPos / totalHeight) * 101;

    setProgress(progress);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <StyledScrollButton
      onClick={scrollToTop}
      isVisible={isVisible}
    >
      <svg
        viewBox="0 0 64 64"
        className="progress"
      >
        <circle
          className="background"
          r="25%"
          cx="50%"
          cy="50%"
        ></circle>
        <circle
          className="chart"
          r="25%"
          cx="50%"
          cy="50%"
          strokeDasharray={`${progress} 100`}
        ></circle>
      </svg>
      <LazyLoadImage
        width={30}
        height={40}
        src={backToTopArrow}
        alt="project image"
      />
    </StyledScrollButton>
  );
};

export default BackToTopButton;