import React from "react";
import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledToggler } from "./styled-toggler";

const Toggle = ({ theme, toggleTheme }) => {
  const { t } = useTranslation("navigations");

  return (
    <StyledToggler
      onClick={toggleTheme}
      title={theme === "light" ? t("themeMode.dark") : t("themeMode.light")}
      data-testid="theme-toggle"
    />
  );
};

Toggle.protoTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired
};

export default Toggle;