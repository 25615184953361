import { gql } from "apollo-boost";

const GET_PAYOUT_REQUISITIONS = gql`
  query getAccountDetailsClient(
    $clientID: String!
    $status_list: [String]
    $sortField: String
    $sortOrder: String
  ) {
    payoutRequisitions(
      client_id: $clientID
      status_list: $status_list
      sort: { field: $sortField, order: $sortOrder }
    ) {
      collection {
        id
        wallet
        status
        createdAt
        amount
        commentary
        isApproved
      }
    }
  }
`;

const GET_ALL_PAYOUT_REQUISITIONS = gql`
  query getAllPayoutRequisitions(
    $page: Int
    $itemsPerPage: Int
    $status: String
    $id: Int
    $wallet: String
    $dateTo: String
    $dateFrom: String
    $amountFrom: String
    $amountTo: String
    $firstname: String
    $lastname: String
    $email: String
    $sortField: String
    $sortOrder: String
  ) {
    payoutRequisitions(
      page: $page
      isApproved: true
      itemsPerPage: $itemsPerPage
      status: $status
      id: $id
      wallet: $wallet
      client_firstname: $firstname
      client_lastname: $lastname
      client_email: $email
      amount: { gt: $amountFrom, lt: $amountTo }
      createdAt: [{ gt: $dateFrom }, { lt: $dateTo }]
      sort: { field: $sortField, order: $sortOrder }
    ) {
      collection {
        id
        usdtType
        wallet
        status
        createdAt
        amount
        commentary
        client {
          id
          firstname
          lastname
          email
        }
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;

const GET_NEW_REQUISITIONS_COUNT = gql`
  query getNewRequisitionsCount {
    payoutRequisitions (status: "NEW", isApproved: true) {
      paginationInfo {
        totalCount
      }
    }
  }
`;

export { GET_PAYOUT_REQUISITIONS, GET_ALL_PAYOUT_REQUISITIONS, GET_NEW_REQUISITIONS_COUNT };
