import styled from "styled-components";

export const StyledFooter = styled.footer`
  padding: 75px 0 15px;
  background-color: ${({ theme }) => theme.bgElements};
  min-height: 429px;

  .footer-author {
    min-height: 19px;
    opacity: 0.75;
  }

  @media screen and (max-width: 992px) {
    padding: 50px 0 110px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 90px
  }
`;

export const StyledFooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 320px)) minmax(400px, 440px);
  grid-gap: 20px;
  grid-template-areas: 'info document social';

  .footer-info-links {
    grid-area: info;
  }

  .footer-document-links {
    grid-area: document;
  }

  .footer-address-links {
    grid-area: address;
  }

  .footer-social-links {
    grid-area: social;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'email work-time'
                          'social-list social-list';

    &__email {
      grid-area: email;
    }

    &__social-list {
      grid-area: social-list;
    }

    &__work-time {
      grid-area: work-time;
    }
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'info document'
                          'social social';
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 100%;
    grid-template-areas: 
                         'info'
                         'document'
                          'social';
    .footer-social-links {
      grid-template-columns: 100%;
      grid-template-areas: 'email'
                           'work-time'
                            'social-list';
    }
  }
`;

export const StyledFooterContentItem = styled.div`
  .footer-item__title {
    min-height: 29px;
    padding-bottom: 12px;
    font-weight: 700;
    opacity: 0.85;
  }

  .footer-item__list {
    li {
      min-height: 29px;
      padding: 5px 0;
      color: ${({ theme }) => theme.neutralColors.second};
      opacity: 0.75;

      a {
        color: ${({ theme }) => theme.neutralColors.second};

        &:hover {
          color: ${({ theme }) => theme.defaultColor};
        }
      }
    }
  }

  .social-wrapper {
    padding: 8px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 32px);
    grid-gap: 15px;

    @media screen and (max-width: 992px) {
      grid-gap: 10px;
    }

    li {
      padding: 0;
      opacity: 1;

      a.social-icon__link {
        font-size: 32px;
        color: rgba(180, 180, 180, 0.7);
        display: block;
        transition: all .3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
`;

export const StyledFooterBlock = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
  @media screen and (max-width: 480px) {
    grid-template-rows: max-content;
  }
`;

export const StyledMerchantsWrapper = styled.div`
  padding: 40px 0;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  @media screen and (max-width: 992px) {
    padding: 25px 0;
  }
`;

export const StyledDeveloperBy = styled.div`
  margin-top: 20px;
  padding-top: 15px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.borderElements};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.75;

  p {
    min-height: 22px;
    font-size: 12px;
    padding-top: 5px;

    span {
      &:hover {
        color: ${({ theme }) => theme.primaryColors.first};
      }
    }
  }
`;