import React, { createContext, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Switch, useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import i18n from "i18next";
import BackToTopButton from "./components/back-to-top/back-to-top.component";
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";
import AutoLogoutMessage from "./components/modal/auto_logout_message";
import UserStatusMessage from "./components/modal/user-status-message.component";
import PageSpinner from "./components/spinner/page-spinner.component";
import TokenClickConversionNewFormat from "./components/traffic/traffic-click-conversion-new.component";
import LoadRouteComponent from "./components/load-router/load-route.component";
import ScrollToTopProvider from "./components/scroll-top-top/scroll-top-top";
import { useDarkMode } from "./components/hooks/use-dark-mode.hooks";
import { darkTheme, lightTheme } from "./components/styles/theme/theme";
import IS_LOGGED_IN from "./graphql/queries/login.query";
import routes from "./routes";

import { authenticationConfig } from "./utils/authenticationConfig";
import { GET_USER_RBAC_DETAILS } from "./graphql/queries/user.query";

import guestRoutes from "./guestRoutes";

import "./assets/css/main.min.css";
import "./assets/fonts/inter/style.css";
import "./assets/fonts/theme-icons/style.css";
import "./assets/images/css-sprite/icons.min.css";
import { GlobalStyle } from "./components/styles/global-style";

import "./i18n";
import { defaultLanguage, responseStatus } from "./utils/consts.util";
import { getTimeOffset } from "./utils/serverTime";

import { PusherService } from "./utils/socketPusherService";

export const AuthContext = createContext({});

const App = () => {
  window.localStorage.removeItem("appLang");

  const [lngFromUrl, setLngFromUrl] = useState(defaultLanguage);

  const [geoData, setGeoData] = useState(null);

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const client = useApolloClient();
  const history = useHistory();

  const { userId } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLngFromUrl(language);
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  useEffect(() => {
    const fetchTimeOffset = async () => {
      await getTimeOffset();
    };

    fetchTimeOffset();
  }, []);

  const [authenticated, setAuthenticated] = useState(!!localStorage.getItem("token"));

  const renderRoutesByAuth = () => {
    return authenticated ?
      routes.map((route, i) => (
        <LoadRouteComponent key={i} {...route} />
      )) :
      guestRoutes.map((route, i) => (
        <LoadRouteComponent key={i} {...route} />
      ));
  };

  const redirectToLogin = () => {
    if (!authenticated && window.location.pathname.includes("panel")) {
      sessionStorage.setItem("link", window.location.pathname);

      return <Redirect to="/login" />;
    }
  };

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const isValidToken = () => {
    axios.post("/api/is-valid-token", {}, authenticationConfig(true)).catch(() => {
      setAuthenticated(false);

      client.writeQuery({
        query: IS_LOGGED_IN,
        data: { isLoggedIn: false }
      });

      localStorage.removeItem("token");
      localStorage.removeItem("country");

      history.push("/login");
    });
  };

  useEffect(() => {
    if (authenticated && !localStorage.getItem("country")) {
      axios.get("https://api.bigdatacloud.net/data/reverse-geocode-client?" +
        "latitude=XXXXXXXXXXXX&longitude=XXXXXXXXXXXX&localityLanguage=en").then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setGeoData(response.data);
          localStorage.setItem("country", response.data.countryCode);
        }
      });
    }

  }, [authenticated]);

  useEffect(() => {
    PusherService.initNewConnection();

    return () => {
      PusherService.unsubscribeFromAllChannels();
    };
  }, []);

  useEffect(() => {
    if (geoData) {
      axios.put("/api/users/" + userId, { lastLoginCountry: geoData.countryCode }, authenticationConfig()).then();
    }
  }, [geoData]);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      isValidToken();
    }
  }, []);

  if (!mountedComponent) return <div />;

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated
      }}
    >
      <ThemeProvider theme={themeMode}>
        <TokenClickConversionNewFormat />
        <HelmetProvider>
          <Helmet>
            <html lang={lngFromUrl} />
          </Helmet>
          <Router>
            <ScrollToTopProvider>
              <React.Suspense fallback={<>...</>}>
                <UserStatusMessage
                  setAuthenticated={setAuthenticated}
                />
              </React.Suspense>
              <Suspense fallback={<PageSpinner />}>
                <AutoLogoutMessage />
                <Header
                  theme={theme}
                  toggleTheme={themeToggler}
                />
                <Switch>
                  {renderRoutesByAuth()}
                  {redirectToLogin()}
                  <Redirect from="*" to="/404" />
                </Switch>
                <Footer theme={theme} />
                <BackToTopButton />
              </Suspense>
            </ScrollToTopProvider>
          </Router>
          <GlobalStyle />
        </HelmetProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
