import { useApolloClient, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { GET_NEW_FEEDBACK_MESSAGE } from "../../graphql/queries/feedback.query";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";

import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationFeedback = () => {
  let permissions = {};
  const client = useApolloClient();

  const { userRole, managerCity } = client.readQuery({
    query: GET_USER_RBAC_DETAILS,
  });

  switch (userRole) {
    case "manager":
      permissions = {citySearch: managerCity.length !== 0 ? managerCity : ""};
      break;
  }
  const [count, setCount] = useState("");
  const { refetch } = useQuery(GET_NEW_FEEDBACK_MESSAGE, {
    variables: {...permissions},
    onCompleted: (data) => {
      if (data) {
        const { feedbackMessages } = data;
        const { totalCount } = feedbackMessages.paginationInfo;
        setCount(totalCount);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    let channel = pusherChannels.FEEDBACK.FEEDBACK_BADGE;

    if (!refetch) {
      return;
    }

    PusherService.connectToSocket(
      channel,
      pusherEvents.FEEDBACK.CREATE,
      () => {refetch();}
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.FEEDBACK.UPDATE,
      () => {refetch();}
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, [refetch]);

  if (count <= 0) return null

  return (
    <StyledBadgeNotification>
      {count}
    </StyledBadgeNotification>
  );
};
export default BadgeNotificationFeedback;
