export default {
  body: "#fff",
  bgElements: "#F8F8F8",
  borderElements: "#CDCDCD",
  lightBg: "#FCFCFC",
  text: "#202020",
  textOpacity: "rgba(32, 32, 32, 0.40)",
  textReadonly: "rgba(32, 32, 32, 0.7)",
  textReadonlyHex: "#2020201A",
  defaultColor: "#FBD304",
  defaultColorSecondary: "#7D7D7D",
  switchPosition: "1px",
  navBarBg: "#F5F5F5",
  navBarBorder: "#CDCDCD",
  border: "#EFEFEF",
  activeInputBorder: "#202020",
  requisitionStatusDefault: "#CCCCCC",
  hoverColor: "#EEEEEE",
  hoverShadow: "#DEE1FD",
  skeletonBg: "rgba(0, 0, 0, 0.11)",
  skeletonBgGradient: "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent)",
  loadingForm: "rgba(250, 250, 255, 0.5)",
  errorRow: "#fbc8c8",
  disabledText: "rgba(32,32,32,0.75)",
  blackText: "#202020",
  whiteText: "#FFFFFF",

  //NEW FORMAT
  primaryColors: {
    first: "#FBD304",
    second: "#F6D734"
  },
  secondaryColors: {
    first: "#B4B4B4",
    second: "#7D7D7D"
  },
  neutralColors: {
    first: "#202020",
    second: "#7D7D7D",
    third: "#CCCCCC",
    fourth: "#F8F8F8"
  },
  dangerColors: {
    first: "#FF4C41",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#FEDEDC"
  },
  successColors: {
    first: "#19BE6F",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#D1F2E2"
  },
  warningColors: {
    first: "#FF9900",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#FFEBCC"
  },
  infoColors: {
    first: "#1971C5",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#D9EDFF"
  },
  homepage: {
    whoAreWeSectionBg: "#F8F8F8"
  },
  selectColors: {
    background: "#FFFCED",
    border: "#FEF6CD"
  }
};