export default {
  body: "#202020",
  bgElements: "#181818",
  borderElements: "#131313",
  lightBg: "#1b1b1b",
  text: "#fff",
  textOpacity: "#fff",
  textReadonly: "rgba(255, 255, 255, 0.7)",
  textReadonlyHex: "#FFFFFF1A",
  defaultColor: "#FBD304",
  defaultColorSecondary: "#7D7D7D",
  switchPosition: "calc(100% - 19px)",
  navBarBg: "#141414",
  navBarBorder: "#181818",
  border: "#303030",
  activeInputBorder: "#fff",
  requisitionStatusDefault: "#7D7D7D",
  hoverColor: "#7D7D7D",
  hoverShadow: "#7D7D7D",
  skeletonBg: "rgba(255, 255, 255, 0.11)",
  skeletonBgGradient: "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent)",
  loadingForm: "rgba(10, 10, 10, 0.5)",
  errorRow: "#5c1717",
  disabledText: "rgba(255,255,255,0.75)",
  blackText: "#202020",
  whiteText: "#FFFFFF",

  //NEW FORMAT
  primaryColors: {
    first: "#FBD304",
    second: "#F6D734"
  },
  secondaryColors: {
    first: "#B4B4B4",
    second: "#7D7D7D"
  },
  neutralColors: {
    first: "#F8F8F8",
    second: "#CCCCCC",
    third: "#7D7D7D",
    fourth: "#202020"
  },
  dangerColors: {
    first: "#FF4C41",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#444447"
  },
  successColors: {
    first: "#19BE6F",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#17584D"
  },
  warningColors: {
    first: "#FF9900",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#455137"
  },
  infoColors: {
    first: "#1971C5",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#20536B"
  },
  homepage: {
    whoAreWeSectionBg: "rgba(125,125,125,0.2)"
  },
  selectColors: {
    background: "#24252F",
    border: "#2F3357"
  }
};