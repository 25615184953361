import { gql } from "apollo-boost";

const GET_FEEDBACK_MESSAGE_BY_ID = gql`
  query getFeedbackMessageById($id: ID!) {
    feedbackMessage(id: $id) {
      id
      firstname
      lastname
      message
      email
      createdAt
      status
      category
      feedbackDetail {
        id
        message
        createdAt
        author
        mediaObjects {
          storage
          contentUrl
        }
      }
    }
  }
`;

const GET_NEW_FEEDBACK_MESSAGE = gql`
  query getNewFeedbackMessage($citySearch: [String]) {
    feedbackMessages(status: "not_viewed", citySearch_list: $citySearch) {
      paginationInfo {
        totalCount
      }
    }
  }
`;

export {
  GET_FEEDBACK_MESSAGE_BY_ID,
  GET_NEW_FEEDBACK_MESSAGE,
};
