import i18next from "i18next";
import Select, { Option } from "rc-select";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory, withRouter } from "react-router-dom";
import {
  defaultLanguage,
  examinationSupporterLangWithOutMainLangInToPathName,
  supportedLanguages
} from "../../../utils/consts.util";
import { StyledLanguageSelect } from "./styled-language-select";

const LanguageSelect = () => {
  const pathname = window.location.pathname;
  const [cookies] = useCookies();
  const history = useHistory();

  const [actualLang, setActualLang] = useState(i18next.language);
  const actualLng = useRef(i18next.language);

  const regex = new RegExp(`^/(${Object.keys(supportedLanguages).join("|")})/`);
  const replacePathName = pathname.replace(regex, "/");

  const handleChangeLang = (value) => {
    setActualLang(value);
    actualLng.current = value;
    i18next.changeLanguage(value);

    const newPath = value === defaultLanguage ? pathname.replace(regex, "/") : `/${value}${replacePathName}`;

    if (window.location.search.length > 0) {
      history.replace(newPath + window.location.search);
    } else {
      history.replace(newPath);
    }
  };

  const getUrlLanguage = (value) => {
    return value.split("/").find((itemLNG => {
      if (examinationSupporterLangWithOutMainLangInToPathName.includes(itemLNG)) {
        return itemLNG;
      }
    })) || defaultLanguage;
  };

  useEffect(() => {
    const path = window.location.href.split(window.location.host)[1];
    if (actualLng.current !== getUrlLanguage(path)) {
      const newPath = actualLng.current === defaultLanguage
        ? pathname.replace(regex, "/")
        : `/${actualLng.current}${replacePathName}`;

      if (window.location.search.length > 0) {
        history.replace(newPath + window.location.search);
      } else {
        history.replace(newPath);
      }
    }

    if (actualLng.current === defaultLanguage && window.location.pathname.includes("/ru/")) {
      const compareUrl = path.split(defaultLanguage)[1];
      history.replace(compareUrl);
    }

    if (actualLng.current !== defaultLanguage &&
      !Object.keys(supportedLanguages).some(item => window.location.pathname.includes(`/${item}`))) {

      const compareUrl = "/" + actualLng.current + path;

      history.replace(compareUrl);
    }
  }, [pathname]);

  return (
    <StyledLanguageSelect
      id="lang"
      className="lang-select-wrapper"
    >
      <Select
        className="lang-select"
        name="lang"
        value={actualLang ? actualLang.toUpperCase() : null}
        defaultValue={cookies["i18next"] ?? null}
        onChange={(value) => handleChangeLang(value)}
        getPopupContainer={() => document.getElementById("lang")}
        dropdownMatchSelectWidth={false}
        direction="rtl"
        data-testid="language-select"
      >
        {Object.entries(supportedLanguages).map(([key, value]) => {
          if (key !== actualLang) {
            return (
              <Option value={key} key={key} data-testid={"language-select-option-" + key}>
                <div className="lang-select__item">
                  <span className="lang-select__pref">{key.toUpperCase()}</span>
                  <span className="lang-select__text">{value.description}</span>
                </div>
              </Option>
            );
          }
        })}
      </Select>
    </StyledLanguageSelect>
  );
};

export default withRouter(LanguageSelect);